once = require './modules/once'

history = document.getElementById 'history'
praise = document.getElementById 'praise'
showMore = document.getElementById 'showMore'
goToShort = document.getElementById 'goToShort'
short = document.getElementById 'short'
showMorePoints = document.getElementById 'showMorePoints'

accordion = document.getElementById 'accordion'

keyPoints = accordion.children[0].getElementsByTagName 'button'
summaries = accordion.children[0].getElementsByClassName 'js-accordion-panel-inner'

addExlanation = (header) ->
  header.classList.add 'relative'

  explanation = document.createElement 'span'
  explanation.textContent = 'click to expand'

  explanation.classList.add 'absolute', 'bottom-0', 'left-0', 'right-0', 'b', 'tc', 'explanation', 'animated', 'bounce'
  explanation.style.animationDelay = '0.5s'

  header.appendChild explanation

for summary in summaries
  summary.classList.add 'overflow-hidden'
  summary.style.height = '0'

for point, i in keyPoints then do (i, point) ->
  point.addEventListener 'click', (e) ->
    e.preventDefault()
    if summaries[i].style.height is 'auto' then summaries[i].style.height = '0'
    else summaries[i].style.height = 'auto'
    explanation = (point.getElementsByClassName 'explanation')[0]
    if explanation? then point.removeChild explanation
    point.scrollIntoView {behavior: 'smooth', alignToTop: true, block: 'start'}

goToHistory = document.getElementById 'goToHistory'

goToHistory.addEventListener 'click', (e) ->
  e.preventDefault()
  history.scrollIntoView {behavior: 'smooth', alignToTop: true, block: 'start'}

  (document.getElementById '1').classList.add 'animated'
  (document.getElementById '1').classList.add 'fadeIn'
  (document.getElementById '1').style.animationDelay = "0.5s"

  (document.getElementById '2').classList.add 'animated'
  (document.getElementById '2').classList.add 'fadeIn'
  (document.getElementById '2').style.animationDelay = "1.5s"

  (document.getElementById '3').classList.add 'animated'
  (document.getElementById '3').classList.add 'fadeIn'
  (document.getElementById '3').style.animationDelay = "2.5s"

  (document.getElementById '4').classList.add 'animated'
  (document.getElementById '4').classList.add 'fadeIn'
  (document.getElementById '4').style.animationDelay = "3.5s"

  if 'hidden' in goToShort.classList
    goToShort.classList.remove 'hidden'
    goToShort.classList.add 'animated'
    goToShort.classList.add 'fadeInDown'
    goToShort.style.animationDelay = "4.5s"
    goToShort.style.display = 'inline'

goToShort.addEventListener 'click', (e) ->
  e.preventDefault()
  short.scrollIntoView {behavior: 'smooth', alignToTop: true, block: 'start'}

firstPointHeader = accordion.children[0].children[0]
firstPointText = accordion.children[0].children[1]

restPoints = (point for point, index in accordion.children[0].children when index >= 2)

addExlanation firstPointHeader

accordion.children[0].removeChild point for point in restPoints

showMorePoints.addEventListener 'click', (e) ->
  e.preventDefault()
  button = (showMorePoints.children)[0]
  showMorePoints.removeChild button
  pointHeader = restPoints.shift()
  pointText = restPoints.shift()

  addExlanation pointHeader

  accordion.removeChild showMorePoints if restPoints.length is 0
  accordion.children[0].appendChild pointHeader
  accordion.children[0].appendChild pointText
  pointHeader.scrollIntoView {behavior: 'smooth', alignToTop: true, block: 'center'}
  showMorePoints.appendChild button

showMorePoints.classList.remove 'hidden'

praiseHeader = praise.children[0]
quotes = (quote for quote, index in praise.children when index < (praise.children.length - 2))

first = quotes[0]
second = quotes[1]
third = quotes[2]
rest = (quote for quote, index in quotes when index > 2)

praise.removeChild quote for quote in rest

showMore.addEventListener 'click', (e) ->
  e.preventDefault()
  button = (showMore.children)[0]
  showMore.removeChild button
  firstAdded = undefined
  for count in [1..3]
    if rest.length > 0
      newQuote = rest.shift()
      newQuote.classList.add 'animated'
      newQuote.classList.add 'fadeIn'
      if count is 1 then firstAdded = newQuote
      praise.insertBefore newQuote, showMore
      firstAdded.scrollIntoView {behavior: 'smooth', alignToTop: true, block: 'start'}
      showMore.appendChild button
    else
      try praise.removeChild showMore catch e

showMore.classList.remove 'hidden'

navigation = require './navigation'
navigation('navigation', disableTransparency: true).watchForScroll()


olderArticles = document.getElementById 'olderArticles'
olderArticles.style.display = 'none'

showOlder = document.getElementById 'showOlder'
showOlder.style.cursor = 'pointer'
showOlder.addEventListener 'click', (e) -> 
  e.preventDefault()
  olderArticles.classList.add 'animated'
  olderArticles.classList.add 'fadeIn'
  olderArticles.style.display = 'block'
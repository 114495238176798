quote = document.getElementById 'quote'

bg = document.getElementById 'bg'
width = document.documentElement.clientWidth
height = document.documentElement.clientHeight

goToAbout = document.getElementById 'goToAbout'
about = document.getElementById 'about'

if width >= height
  bg.style.height = "100%"
  quote.style.height = "90%"
  quote.style.width = "65%" if width < 1200
  quote.style.width = "70%" if width > 1200
  quote.style.color = 'white'
  quote.style.position = 'absolute'
  quote.style.bottom = '0px'
  quote.style.top = '0px'
  quote.classList.add 'dark-shadow'

goToAbout.addEventListener 'click', (e) ->
  e.preventDefault()
  about.scrollIntoView {behavior: 'smooth', alignToTop: true, block: 'start'}


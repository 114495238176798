animations =
  animation: 'animationend'
  OAnimation: 'oAnimationEnd'
  MozAnimation: 'mozAnimationEnd'
  WebkitAnimation: 'webkitAnimationEnd'

onAnimationEnd = (el, cb) ->
  for animation, eventName of animations then do (el, eventName, cb) ->
    el.addEventListener eventName, cb, false

animationEnd = ((el) ->
  for t of animations
    if el.style[t] != undefined
      return animations[t]
  return
)(document.createElement('div'))

module.exports = {animationEnd, onAnimationEnd}

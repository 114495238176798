smoothscroll = require 'smoothscroll-polyfill'
stickybits = require 'stickybits'
window.navigation = require './navigation'
CookiesEuBanner = require 'cookies-eu-banner'
#GAnalytics = require 'ganalytics'
once = require './modules/once'
{parse} = require 'cookie'
AOS = require 'aos'

enableAOS = ->
  allElements = document.getElementsByTagName '*'
  for element in allElements
    attribute = element.getAttribute 'data-aos'
    if attribute?
      animation = attribute.replace 'nojs-', ''
      element.setAttribute 'data-aos', animation
  AOS.init offset: 30

window.isIE = ->
  ua = window.navigator.userAgent
  #Check the userAgent property of the window.navigator object
  msie = ua.indexOf('MSIE ')
  # IE 10 or older
  trident = ua.indexOf('Trident/')
  #IE 11
  msie > 0 or trident > 0

isPrivacyPage = try window.location.pathname.match /privacy/ catch e then undefined

cookie = parse document.cookie
twitter = document.getElementById 'twitter'
quote = document.getElementById 'quote'

accept = document.getElementById 'cookies-eu-accept'
accept?.addEventListener 'click', (e) -> e.preventDefault()

reject = document.getElementById 'cookies-eu-reject'
reject?.addEventListener 'click', (e) -> e.preventDefault()

initializeLinkedInInsights = ->
  document.body.insertAdjacentHTML 'beforeend', """
    <script type="text/javascript">
    _linkedin_partner_id = "2145420";
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    </script><script type="text/javascript">
    (function(){var s = document.getElementsByTagName("script")[0];
    var b = document.createElement("script");
    b.type = "text/javascript";b.async = true;
    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    s.parentNode.insertBefore(b, s);})();
    </script>
  """

unless isPrivacyPage or (cookie.hasConsent is false)
  new CookiesEuBanner ->
    if twitter?
      quote.classList.add 'w-50-ns' unless 'w-50-ns' in quote.classList
      feed = document.createElement 'script'
      feed.src = 'https://platform.twitter.com/widgets.js'
      feed.charset = 'utf-8'
      document.body.appendChild feed
      twitter.classList.remove 'hidden' if 'hidden' in twitter.classList

    initializeAnalytics()
    initializeLinkedInInsights()

    return
  , true

stickybits '.sticky'
smoothscroll.polyfill()

enableAOS() unless isIE()

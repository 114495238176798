absUrl = require './modules/absUrl'

{onAnimationEnd} = require './modules/animationEnd'
once = require './modules/once'

speaking = document.getElementById 'speaking'
speaking.classList.add 'h-100'
speaking.classList.add 'pt6'
speaking.classList.add 'overflow-y-auto'
speaking.style.display = 'none'

speakingWrapper = document.getElementById 'speakingWrapper'
speakingWrapper.style.display = 'none'

speakingTitle = document.getElementById 'speakingTitle'
speakingTitle.style.display = 'none'

consulting = document.getElementById 'consulting'
consulting.classList.add 'h-100'
consulting.classList.add 'pt6'
consulting.classList.add 'overflow-y-auto'
consulting.style.display = 'none'

consultingWrapper = document.getElementById 'consultingWrapper'
consultingWrapper.style.display = 'none'

consultingTitle = document.getElementById 'consultingTitle'
consultingTitle.style.display = 'none'

restructuring = document.getElementById 'restructuring'
restructuring.classList.add 'h-100'
restructuring.classList.add 'pt6'
restructuring.classList.add 'overflow-y-auto'
restructuring.style.display = 'none'

restructuringWrapper = document.getElementById 'restructuringWrapper'
restructuringWrapper.style.display = 'none'

restructuringTitle = document.getElementById 'restructuringTitle'
restructuringTitle.style.display = 'none'

closeButton = document.getElementById 'closeButton'
navMenu = document.getElementById 'navBar'

linkContainer = document.getElementById 'linkContainer'
cover = document.getElementById 'cover'
footer = document.getElementById 'footer'

linkContainer.classList.remove 'hidden'

hideFooter = ->
  footer.style.display = 'none'

showFooter = ->
  footer.style.display = 'block'

hideNavMenu = -> window.scrollLock = 1; window.hideNavigation()

showNavMenu = -> window.scrollLock = 0; window.showNavigation()

showCloseButton = (cb) ->
  closeButton.classList.add 'animated'
  closeButton.classList.add 'fadeIn'
  closeButton.style.display = 'block'

hideCloseButton = ->
  closeButton.style.display = 'none'

showRestructuring = (cb) ->
  history.pushState {}, '', absUrl('/services/restructuring')
  restructuring.classList.add 'animated'
  restructuring.classList.add 'fadeIn'
  restructuring.style.display = 'block'
  restructuringWrapper.style.display = 'block'
  window.onpopstate = ->  closeButton.click()

hideRestructuring = (cb) ->
  history.pushState {}, '', absUrl('/services')
  restructuring.style.display = 'none'
  restructuringWrapper.style.display = 'none'

showSpeaking = (cb) ->
  history.pushState {}, '', absUrl('/services/speaking')
  speaking.classList.add 'animated'
  speaking.classList.add 'fadeIn'
  speaking.style.display = 'block'
  speakingWrapper.style.display = 'block'
  window.onpopstate = ->  closeButton.click()

hideSpeaking = ->
  history.pushState {}, '', absUrl('/services')
  speaking.style.display = 'none'
  speakingWrapper.style.display = 'none'

showConsulting = (cb) ->
  history.pushState {}, '', absUrl('/services/consulting')
  consulting.classList.add 'animated'
  consulting.classList.add 'fadeIn'
  consulting.style.display = 'block'
  consultingWrapper.style.display = 'block'
  window.onpopstate = ->  closeButton.click()

hideConsulting = ->
  history.pushState {}, '', absUrl('/services')
  consulting.style.display = 'none'
  consultingWrapper.style.display = 'none'

hideLinkContainer = ->
  linkContainer.style.display = 'none'

showLinkContainer = ->
  linkContainer.style.display = 'block'

show =
  speaking: (done) ->
    hideNavMenu()
    showCloseButton()
    showSpeaking()

    once closeButton, 'click', (e) ->
      e.preventDefault()
      hideCloseButton()
      hideSpeaking()
      done()
  consulting: (done) ->
    hideNavMenu()
    showCloseButton()
    showConsulting()

    once closeButton, 'click', (e) ->
      e.preventDefault()
      hideCloseButton()
      hideConsulting()
      done()
  restructuring: (done) ->
    hideNavMenu()
    showCloseButton()
    showRestructuring()

    once closeButton, 'click', (e) ->
      e.preventDefault()
      hideCloseButton()
      hideRestructuring()
      done()


[currentBg, previousBg] = [undefined, undefined]

for category in [
  'speaking'
  'consulting'
  'restructuring'
] then do (category) ->
  linkElement = document.getElementById "#{category}Link"
  linkElement.addEventListener 'click', (e) ->
    e.preventDefault()
    hideFooter()
    hideLinkContainer()
    show["#{category}"] ->
      showNavMenu()
      showLinkContainer()
      showFooter()
  linkElement.addEventListener 'mouseover', (e) ->
    bgUrl = absUrl("/images/#{category}.jpg")
    currentBg = "url('#{bgUrl}')"
    previousBg = currentBg unless previousBg?

    if previousBg is currentBg
      linkContainer.style.backgroundImage = currentBg
      linkContainer.style.backgroundPosition = 'center'
      cover.style.backgroundColor = "rgba( 0, 0, 0, .5 )"
      cover.style.transition = "background-color 1000ms linear"
    else
      cover.style.backgroundColor = "rgba( 0, 0, 0, 1 )"
      cover.style.transition = "background-color 300ms linear"
      setTimeout ->
        linkContainer.style.backgroundImage = currentBg
        cover.style.backgroundColor = "rgba( 0, 0, 0, .5 )"
        cover.style.transition = "background-color 1000ms linear"
      , 300

  linkElement.addEventListener 'mouseout', (e) ->
    previousBg = currentBg
    currentBg = undefined
    cover.style.backgroundColor = "rgba( 0, 0, 0, 1 )"
    cover.style.transition = "background-color 400ms linear"

bindRaf = require './modules/bindRaf'
isInViewport = require './modules/isInViewport'
SimpleLightbox = require 'simple-lightbox'

gallery = ->
  horizontalContainers = document.getElementsByClassName 'gallery-scroll-container'
  verticalContainer = (document.getElementsByClassName 'gallery-vertical-scroll-container')[0]

  gallery = document.getElementById 'gallery'
  loaded = {}

  handlePhotos = ->
    lightbox = new SimpleLightbox elements: '.imageGallery a'
    if verticalContainer?
      photos = verticalContainer.children
      loaded.children = []

      for photo, index in photos when isInViewport photo
        style = photo.children[0].children[0].getAttribute 'data-style'

        photo.children[0].children[0].setAttribute 'style', style
        loaded.children.push i

      do (photos, loaded) ->
        document.addEventListener 'scroll', bindRaf((->
          for photo, i in photos when i not in loaded.children
            continue unless isInViewport photo
            style = photo.children[0].children[0].getAttribute 'data-style'
            photo.children[0].children[0].setAttribute 'style', style
            loaded.children.push i
            break
        ), true)

        gallery.addEventListener 'scroll', bindRaf((->
          for photo, i in photos when i not in loaded.children
            continue unless isInViewport photo
            style = photo.children[0].children[0].getAttribute 'data-style'
            photo.children[0].children[0].setAttribute 'style', style
            loaded.children.push i
            break
        ), true)

    unless horizontalContainers.length is 0
      for container, index in horizontalContainers
        [rightIndicator, leftIndicator, photosContainer] = container.children
        {width: photoWidth} = photosContainer.children[0].getBoundingClientRect()

        loaded[index] = []

        for i in [0..5] when photosContainer.children[i]?
          style = photosContainer.children[i].children[0].children[0].getAttribute 'data-style'
          photosContainer.children[i].children[0].children[0].style = style
          loaded[index].push i

        {scrollWidth, scrollLeft} = photosContainer
        {width} = photosContainer.getBoundingClientRect()



        if scrollLeft > 0 then leftIndicator.classList.remove 'hidden'
        else leftIndicator.classList.add 'hidden'

        do (rightIndicator, leftIndicator, photosContainer) -> rightIndicator.addEventListener 'click', (e) ->
          e.preventDefault()
          photosContainer.scrollLeft += photoWidth

        do (rightIndicator, leftIndicator, photosContainer) -> leftIndicator.addEventListener 'click', (e) ->
          e.preventDefault()
          photosContainer.scrollLeft -= photoWidth

        do (rightIndicator, leftIndicator, photosContainer, index, loaded) ->
          photosContainer.addEventListener 'scroll', bindRaf((->
            {scrollWidth, scrollLeft} = photosContainer
            {width} = photosContainer.getBoundingClientRect()

            for photo, i in photosContainer.children when i not in loaded[index]
              continue unless isInViewport photo
              style = photosContainer.children[i].children[0].children[0].getAttribute 'data-style'
              photosContainer.children[i].children[0].children[0].style = style
              loaded[index].push i
              break

            if scrollLeft < scrollWidth - width then rightIndicator.classList.remove 'hidden'
            else rightIndicator.classList.add 'hidden'

            if scrollLeft > 0 then leftIndicator.classList.remove 'hidden'
            else leftIndicator.classList.add 'hidden'

            return
          ), true)

  handlePhotos


module.exports = gallery

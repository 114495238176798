absUrl = require './modules/absUrl'
{parse, serialize} = require 'cookie'
cookie = parse document.cookie

delete_cookie = (name) ->
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  return

status = document.getElementById 'currentStatus'
label = document.createElement 'label'
label.classList.add 'db', 'pa3'
input = document.createElement 'input'
input.type = 'checkbox'
input.disabled = true

if cookie?.hasConsent?
  input.checked = cookie.hasConsent
else input.checked = false

span = document.createElement 'span'
span.classList.add 'ph3'
span.textContent = 'Use non Strictly Necessary Cookies'

label.appendChild input
label.appendChild span

reset = document.createElement 'button'
reset.textContent = 'Reset cookies'
reset.addEventListener 'click', (e) ->
  delete_cookie 'hasConsent'
  window.location.href = absUrl('/privacy')

currentStatus.appendChild label
currentStatus.appendChild reset


bindRaf = (fn, throttle) ->
  isRunning = undefined
  that = undefined
  args = undefined

  run = ->
    isRunning = false
    fn.apply that, args
    return

  ->
    that = this
    args = arguments
    if isRunning and throttle
      return
    isRunning = true
    requestAnimationFrame run
    return

module.exports = bindRaf

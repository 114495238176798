goToId = (id) -> document.getElementById(id).scrollIntoView {behavior: 'smooth', alignToTop: true, block: 'start'}

(document.getElementById 'bouncer').addEventListener 'click', (e) ->
  e.preventDefault()
  goToId 'about'


document.addEventListener 'aos:in:history', (e) ->
  (document.getElementById '1').classList.add 'animated'
  (document.getElementById '1').classList.add 'fadeIn'
  (document.getElementById '1').style.animationDelay = "0.5s"

  (document.getElementById '2').classList.add 'animated'
  (document.getElementById '2').classList.add 'fadeIn'
  (document.getElementById '2').style.animationDelay = "1.5s"

  (document.getElementById '3').classList.add 'animated'
  (document.getElementById '3').classList.add 'fadeIn'
  (document.getElementById '3').style.animationDelay = "2.5s"

  (document.getElementById '4').classList.add 'animated'
  (document.getElementById '4').classList.add 'fadeIn'
  (document.getElementById '4').style.animationDelay = "3.5s"

if isIE()
  needFix = document.getElementsByClassName 'ieStickyFix'
  for element in needFix then do (needFix) ->
    element.classList.remove 'w-50-l'

isInViewport = require './modules/isInViewport'
bindRaf = require './modules/bindRaf'

navigation = (className, opts) ->
  disableTransparency = opts.disableTransparency or 0

  nikos = document.getElementById 'nikos'
  breakpoint = document.getElementById 'breakpoint'

  nikosIsClipped = -> 'fadeOut' in nikos.classList

  clipNikos = -> unless nikosIsClipped()
    nikos.classList.add 'animated' unless 'animated' in nikos.classList
    nikos.classList.remove 'fadeIn' if 'fadeIn' in nikos.classList
    nikos.classList.add 'fadeOut' unless 'fadeOut' in nikos.classList

  unclipNikos = -> if nikosIsClipped()
    nikos.classList.add 'animated' unless 'animated' in nikos.classList
    nikos.classList.remove 'fadeOut' if 'fadeOut' in nikos.classList
    nikos.classList.add 'fadeIn' unless 'fadeIn' in nikos.classList

  inLanding = -> isInViewport breakpoint

  if inLanding()? then clipNikos() else unclipNikos() # hide name in nav if in landing

  watchForScroll = ->
    stickiness = 40

    prevScrollpos = window.pageYOffset
    navis = document.getElementsByClassName(className)

    primaryElements = document.getElementsByClassName 'primary'
    secondaryElements = document.getElementsByClassName 'secondary'
    menuButton = (document.getElementsByClassName 'feather-menu')[0]

    primaryColor = primaryElements[0].style.color
    secondaryColor = secondaryElements[0].style.color
    backgroundColor = navis[0].style.backgroundColor

    window.hideNavigation = -> for navi in navis then do (navi) ->
      scrollHeight = navi.scrollHeight
      navi.style.top = "-#{scrollHeight}px"

    window.showNavigation = -> for navi in navis then do (navi) -> navi.style.top = '0'

    makeNaviTransparent = -> unless disableTransparency then for navi in navis
      navi.style.backgroundColor = 'transparent' # set transparency
      navi.classList.remove 'shadow-3' # remove shadow-3

      # make all fonts white
      el.style.color = 'white' for el in primaryElements
      el.style.color = 'white' for el in secondaryElements

      # make menu button white
      menuButton.style.color = 'white'

    makeNaviDefault = -> for navi in navis
      navi.style.backgroundColor = backgroundColor # redo bgColor
      navi.classList.add 'shadow-3' # readd shadow

      # theme fonts
      el.style.color = primaryColor for el in primaryElements
      el.style.color = secondaryColor for el in secondaryElements

      # redo menu button
      menuButton.style.color = primaryColor

    makeNaviTransparent() unless (window.pageYOffset >= stickiness) or (document.documentElement.clientWidth < 480)

    window.scrollLock = 0 # ugly hack for media page edge case

    window.addEventListener 'scroll', bindRaf((->
      if inLanding() then clipNikos() else unclipNikos() # hide name in nav if in landing
      return if scrollLock # ugly hack is used here | when the navigation is visible, the page is locked on media selection
      currentScrollPos = window.pageYOffset

      makeNaviTransparent() if (currentScrollPos <= 20) and (document.documentElement.clientWidth > 480)
      makeNaviDefault() if currentScrollPos > 20

      if (prevScrollpos > currentScrollPos) or (currentScrollPos <= stickiness)
#                                            Allow stickiness in px ^^
        showNavigation()
      else (hideNavigation navis) unless isIE()

      prevScrollpos = currentScrollPos
      return
    ), true)

  {watchForScroll}

module.exports = navigation

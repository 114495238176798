createPlayableVideo = (videoId) ->
  playableVideo = document.createElement 'iframe'
  playableVideo.classList.add 'w-100'
  playableVideo.classList.add 'h-100'
  playableVideo.classList.add 'b--none'
  playableVideo.src = "https://www.youtube-nocookie.com/embed/#{videoId}?autoplay=1&enablejsapi=1"
  playableVideo.setAttribute 'allowFullScreen', 'true'
  playableVideo.setAttribute 'frameborder', '0'
  playableVideo.setAttribute 'allowscriptaccess', 'always'
  playableVideo


player = document.getElementById 'player'
currentVideo = player.children[0]

currentVideo.addEventListener 'click', (e) ->
  e.preventDefault()
  [..., videoId] = currentVideo.href.split '/'
  history.pushState {}, '', currentVideo.href

  playableVideo = createPlayableVideo videoId
  player.replaceChild playableVideo, currentVideo

urljoin = require 'url-join'

isRootMounted = serverRoot in ['/', '', undefined]

#module.exports = (url)-> if isRootMounted then url else urljoin(serverRoot, if url.startsWith('./') then url.substr(2) else url)

module.exports = (url)-> 
    if isRootMounted then url 
    else 
        trimmedUrl = if url.startsWith('./') then url.substr(2) else url
        urljoin(serverRoot, trimmedUrl)





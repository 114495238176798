absUrl = require './modules/absUrl'
once = require './modules/once'
galleryHandler = require './gallery'

press = document.getElementById 'press'
press.classList.add 'h-100'
press.classList.add 'pt6'
press.classList.add 'overflow-y-auto'
press.style.display = 'none'

pressTitle = document.getElementById 'pressTitle'
pressTitle.style.display = 'none'

gallery = document.getElementById 'gallery'
gallery.classList.add 'h-100'
gallery.classList.add 'pt6'
gallery.classList.add 'overflow-y-auto'
gallery.style.display = 'none'

handlePhotos = galleryHandler()

galleryTitle = document.getElementById 'galleryTitle'
galleryTitle.style.display = 'none'

videosTitle = document.getElementById 'videosTitle'
videosTitle.style.display = 'none'
videos = document.getElementById 'videos'
videos.style.display = 'none'

closeButton = document.getElementById 'closeButton'
navMenu = document.getElementById 'navBar'

linkContainer = document.getElementById 'linkContainer'
cover = document.getElementById 'cover'
footer = document.getElementById 'footer'

linkContainer.classList.remove 'hidden'

videoUrl = undefined

hideFooter = ->
  footer.style.display = 'none'

showFooter = ->
  footer.style.display = 'block'

hideNavMenu = -> window.scrollLock = 1; window.hideNavigation()

showNavMenu = -> window.scrollLock = 0; window.showNavigation()

showCloseButton = (cb) ->
  closeButton.classList.add 'animated'
  closeButton.classList.add 'fadeIn'
  closeButton.style.display = 'block'


hideCloseButton = ->
  closeButton.style.display = 'none'

pauseVideo = ->
  try
    player
      .children[0]
      .contentWindow
      .postMessage '{"event":"command","func": "pauseVideo", "args":""}', '*'
  catch e

showVideos = (cb) ->
  history.pushState {}, '', absUrl('/media/videos')
  videos.classList.add 'animated'
  videos.classList.add 'fadeIn'
  videos.style.display = 'block'
  if videoUrl then history.pushState {}, '', absUrl(videoUrl)
  window.onpopstate = ->  closeButton.click()

hideVideos = (cb) ->
  history.pushState {}, '', absUrl('/media')
  videos.style.display = 'none'

showPress = (cb) ->
  history.pushState {}, '', absUrl('/media/press')
  press.classList.add 'animated'
  press.classList.add 'fadeIn'
  press.style.display = 'block'
  window.onpopstate = ->  closeButton.click()

hidePress = ->
  history.pushState {}, '', absUrl('/media')
  press.style.display = 'none'

galleries = 0

showGallery = (cb) ->
  history.pushState {}, '', absUrl('/media/gallery')
  gallery.classList.add 'animated'
  gallery.classList.add 'fadeIn'
  gallery.style.display = 'block'

  handlePhotos() unless galleries isnt 0
  galleries += 1

  window.onpopstate = ->  closeButton.click()

hideGallery = ->
  history.pushState {}, '', absUrl('/media')
  gallery.style.display = 'none'

hideLinkContainer = ->
  linkContainer.style.display = 'none'

showLinkContainer = ->
  linkContainer.style.display = 'block'

show =
  press: (done) ->
    hideNavMenu()
    showCloseButton()
    showPress()

    once closeButton, 'click', (e) ->
      e.preventDefault()
      hideCloseButton()
      hidePress()
      done()
  gallery: (done) ->
    hideNavMenu()
    showCloseButton()
    showGallery()

    once closeButton, 'click', (e) ->
      e.preventDefault()
      hideCloseButton()
      hideGallery()
      done()
  videos: (done) ->
    hideNavMenu()
    showCloseButton()
    showVideos()

    once closeButton, 'click', (e) ->
      e.preventDefault()
      hideCloseButton()
      hideVideos()
      done()

[currentBg, previousBg] = [undefined, undefined]

do menu = -> for category in [
  'press'
  'gallery'
  'videos'
] then do (category) ->
  linkElement = document.getElementById "#{category}Link"
  linkElement.addEventListener 'click', (e) ->
    e.preventDefault()
    hideFooter()
    hideLinkContainer()
    show["#{category}"] ->
      pauseVideo()
      showNavMenu()
      showLinkContainer()
      showFooter()
  linkElement.addEventListener 'mouseover', (e) ->
    bgUrl = absUrl("/images/#{category}.jpg")
    currentBg = "url('#{bgUrl}')"

    previousBg = currentBg unless previousBg?

    if previousBg is currentBg
      linkContainer.style.backgroundImage = currentBg
      linkContainer.style.backgroundPosition = 'center'
      cover.style.backgroundColor = "rgba( 0, 0, 0, .5 )"
      cover.style.transition = "background-color 1000ms linear"
    else
      cover.style.backgroundColor = "rgba( 0, 0, 0, 1 )"
      cover.style.transition = "background-color 300ms linear"
      setTimeout ->
        linkContainer.style.backgroundImage = currentBg
        cover.style.backgroundColor = "rgba( 0, 0, 0, .5 )"
        cover.style.transition = "background-color 1000ms linear"
      , 300
  linkElement.addEventListener 'mouseout', (e) ->
    previousBg = currentBg
    currentBg = undefined
    cover.style.backgroundColor = "rgba( 0, 0, 0, 1 )"
    cover.style.transition = "background-color 400ms linear"

createPlayableVideo = (videoId) ->
  playableVideo = document.createElement 'iframe'
  playableVideo.classList.add 'w-100'
  playableVideo.classList.add 'h-100'
  playableVideo.classList.add 'b--none'
  playableVideo.src = "https://www.youtube-nocookie.com/embed/#{videoId}?autoplay=1&enablejsapi=1"
  playableVideo.setAttribute 'allowFullScreen', 'true'
  playableVideo.setAttribute 'frameborder', '0'
  playableVideo.setAttribute 'allowscriptaccess', 'always'
  playableVideo


player = document.getElementById 'player'
currentVideo = player.children[0]

currentVideo.addEventListener 'click', (e) ->
  e.preventDefault()
  [..., videoId] = currentVideo.href.split '/'
  history.pushState {}, '', currentVideo.href
  videoUrl = currentVideo.href

  playableVideo = createPlayableVideo videoId
  player.replaceChild playableVideo, currentVideo

playlist = document.getElementById 'playlist'
for video in playlist.children then do (video) ->
  video.addEventListener 'click', (e) ->
    e.preventDefault()

    [..., videoId] = video.href.split '/'
    history.pushState {}, '', video.href
    videoUrl = video.href
    playableVideo = createPlayableVideo videoId

    player = document.getElementById 'player'
    activeVideo = player.children[0]

    player.replaceChild playableVideo, activeVideo
    player.scrollIntoView {alignToTop: true, behavior: 'smooth', block: 'start'}
